import React from "react"
import Layout from "../components/layout"
import { LangContext } from "../components/globals/lang-context"
import SEO from "../components/seo"
import {
  JumboPag,
  Timeline,
  WeAre,
  Media,
  Alliance,
} from "../components/sections"
import { LABELS_PAGES_TITLES } from "../components/constants/languages"

import contacto_1 from "../images/contact/contacto_1.png"
import contacto_2 from "../images/contact/contacto_2.png"
import contacto_3 from "../images/contact/contacto_3.png"

const items = [
  {
    src: contacto_1,
    altText: "Slide 1",
    caption: "Slide 1",
  },
  {
    src: contacto_2,
    altText: "Slide 2",
    caption: "Slide 2",
  },
  {
    src: contacto_3,
    altText: "Slide 3",
    caption: "Slide 3",
  },
]

const UsPage = () => (
  <Layout>
    <LangContext>
      {({ lang }) => (
        <>
          <SEO title={LABELS_PAGES_TITLES.US[lang]} />
          <JumboPag title={LABELS_PAGES_TITLES.US[lang]} items={items} />
          <Timeline lang={lang} />
          <WeAre lang={lang} />
          <div id="alianzas" />
          <Alliance lang={lang} />
          <Media lang={lang} />
        </>
      )}
    </LangContext>
  </Layout>
)
export default UsPage
